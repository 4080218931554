import { postWithAuth, fetchWithAuth } from "../Helpers/authHelper";
import { API_URL } from "../config";

export async function handleLandingPageActions({ request, params }) {

  let data = await request.formData()

  const action = data.get("Action")

  switch (action) {
    case "pint":
       await postWithAuth(API_URL + "sale/PaidToPint")
      break;

    case "artists":
      await fetchWithAuth(API_URL + "artist/SendOpenStats")
      break;

    default:
      break;
  }

  // if (result.success) {
  //   toast.success("Artist updated successfully.")
  // } else {
  //   toast.error("Failed to update artist.")
  // }

  return null
}

