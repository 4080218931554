import * as React from 'react';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { ListItemIcon } from '@mui/material';


export const getAvatar = (item, getAvatarSrc) => {

  if (!getAvatarSrc) {
    return ""
  }

  let src = getAvatarSrc(item)

  if (!src || src.length === 0) {
    return ""
  }

  return (
    <>
      <ListItemAvatar>
        <Avatar src={src} />
      </ListItemAvatar>
    </>
  )
}

export const getIconAvatar = (item, getIconAvatarSrc) => {

  if (!getIconAvatarSrc) {
    return ""
  }

  let icon = getIconAvatarSrc(item)

  if (!icon) {
    return ""
  }

  return (
    <>
      <ListItemAvatar>
        {icon}
      </ListItemAvatar>
    </>
  )
}


export const getIcon = (item, getIcon) => {

  if (!getIcon) {
    return ""
  }

  let icon = getIcon(item)

  if (!icon || icon.length === 0) {
    return ""
  }

  return (
    <>
      <ListItemIcon>
        {icon}
      </ListItemIcon>
    </>
  )
}

export default function ListInternal(props) {

  return (
    <List component={props.component} sx={{ width: '100%'  }} className={props.className}>

      {props.data.map((item, i) => {
        return (
          <React.Fragment key={"List-" + i}>
            <ListItemButton alignItems="flex-start"
              onClick={() => !props.onClick ? "" : props.onClick(item)}
              selected={props.selectedIndex === i}
            >
              {
                getAvatar(item, props.getAvatarSrc)
              }
              {
                getIconAvatar(item, props.getIconAvatarSrc)
              }
              {
                getIcon(item, props.getIcon)
              }

              <ListItemText
                sx={{ wordWrap: "break-word" }}
                primary={!props.getPrimaryText ? "" : props.getPrimaryText(item)}
                secondary={
                  <React.Fragment>
                    <Typography
                      sx={{ display: 'inline', color: "" }}
                      component="span"
                      variant="body2"
                      color="text.primary"
                    >
                      {!props.getSecondaryText ? "" : props.getSecondaryText(item)}
                    </Typography>
                  </React.Fragment>
                }
              />

            </ListItemButton>
            {
              i !== (props.data.length - 1) 
              ? <Divider component="li" />
              : ""
            }
          </React.Fragment>)
      })
      }
    </List>
  );
}