import { useLoaderData, useSubmit } from "react-router-dom";
import React from 'react';
import { Box, Divider, Grid, Typography } from "@mui/material";
import ListInternal from "./List";

export default function LandingPage() {
    const label = useLoaderData()

    const labelData = getLabelData(label)
    const submit = useSubmit()

    return (
        <>
            <Grid spacing={1} container>
                <Grid item xs={12}>
                    <Box textAlign={'center'}>
                        <Typography variant="h4">
                            Landing Page
                        </Typography>
                    </Box>
                    <br></br>
                    <Divider></Divider>
                    <br></br>
                </Grid>

                <Grid item xs={3}></Grid>
                <Grid item xs={6}>
                    <ListInternal
                        data={labelData}
                        getPrimaryText={getPrimaryText}
                        onClick={(item) => onClick(item, submit)}
                        className={"labelList"}
                    />
                </Grid>
                <Grid item xs={3}></Grid>

            </Grid>
        </>
    );
}

const handleSubmit = (submit, action) => {
    let formData = new FormData();
    formData.append("Action", action);
    submit(formData, { method: "post" });
}

const getPrimaryText = (data) => {
    return (
        <>
            <div style={{width: "100%"}}>{data[0]} <span style={{float: "right"}}> {data[1]}</span></div>
        </>)

}

const onClick = (item, submit) => {

    if (!item || item.length < 3) {
        return;
    }

    let action = item[2]

    switch (action) {
        case "pint":
            if (!window.confirm("Did you pay " + item[1] + " to Pint?")) {
                return
            }
          break;
    
        case "artists":
            if (!window.confirm("Do you want to send the stats to all artists?")) {
                return;
            }
          break;
    
        default:
          break;
      }

    handleSubmit(submit, action)
}


const getLabelData = (label) => {
    var ogLabelAmount = label.LabelAmount
    var tunoAmount = 30
    var newLabelAmountWithoutTuno = ogLabelAmount - tunoAmount

    return [
        ["Total Artist Paid:", label.PaidToArtist + "€"],
        ["Total Artist Open:", label.PaidToArtistMissing + "€", "artists"],
        ["Total To Pint Open:", label.PaidToPintMissing + "€", "pint"],
        ["Total Label Win:", newLabelAmountWithoutTuno.toFixed(2) + "€"],
        ["Tuno Amount:", tunoAmount.toFixed(2) + "€"],
        ["Tracks:", label.Tracks],
        ["Artists:", label.Artists],
    ]
}