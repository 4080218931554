import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './App.css'
import "react-toastify/dist/ReactToastify.css";


import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import ErrorPage from './Components/ErrorPage';
import Review from './Components/Review';
import { loadAllReviews, loadReview } from './Loaders/reviews';
import { AuthProvider } from 'react-oidc-context';
import Dashboard from './Components/Dashboard';
import { ToastContainer } from 'react-toastify';
import { createReview, updateReview } from './Actions/reviews';
import ReviewOverview from './Components/ReviewOverview';
import CreateReview from './Components/CreateReview';
import ReleaseTaskOverview from './Components/ReleaseTaskOverview';
import { loadAllReleaseTasks, loadAllUsers, loadReleaseTaskData } from './Loaders/releaseTasks';
import ReleaseTask from './Components/ReleaseTask';
import { updateReleaseTask } from './Actions/releaseTask';
import { loadAllDashboardData } from './Loaders/dashboard';
import { updateSelectedArtist } from './Actions/dashboard';
import ArtistOverview from './Components/ArtistOverview'
import Artist from './Components/Artist';
import { loadArtist } from './Loaders/artist';
import { createArtist, updateArtist } from './Actions/artist';
import CreateArtist from './Components/CreateArtist';
import CreateReleaseTask from './Components/CreateReleaseTask';
import TrackOverview from './Components/TrackOverview';
import Track from './Components/Track';
import { loadArtistTracks, loadReleaseTags, loadTrack } from './Loaders/track';
import { createTrack, updateReleaseTaskForTrack } from './Actions/track';
import CreateTrack from './Components/CreateTrack';
import LandingPage from './Components/LandingPage';
import { loadAllLandingPageData } from './Loaders/landingPage';
import { handleLandingPageActions } from './Actions/landingPage';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Dashboard />,
    errorElement: <ErrorPage />,
    loader: loadAllDashboardData,
    action: updateSelectedArtist,
    id: "dashboard",
    children: [
      {
        path: "/",
        element: <LandingPage />,
        loader: loadAllLandingPageData,
        action: handleLandingPageActions
      },
      {
        path: "reviews/:Id",
        element: <Review />,
        loader: loadReview,
        action: updateReview
      },
      {
        path: "reviews",
        element: <ReviewOverview />,
        loader: loadAllReviews,
      },
      {
        path: "reviews/create",
        element: <CreateReview />,
        action: createReview
      },
      {
        path: "tasks",
        element: <ReleaseTaskOverview />,
        loader: loadAllReleaseTasks
      },
      {
        path: "tasks/:Id",
        element: <ReleaseTask />,
        loader: loadReleaseTaskData,
        action: updateReleaseTask
      },
      {
        path: "tasks/create",
        element: <CreateReleaseTask />,
        loader: loadAllUsers,
      },
      {
        path: "artists",
        element: <ArtistOverview />,
      },
      {
        path: "artists/create",
        element: <CreateArtist />,
        action: createArtist
      },
      {
        path: "artists/:ArtistName",
        element: <Artist />,
        loader: loadArtist,
        action: updateArtist
      },
      {
        path: "artists/:ArtistName/tracks",
        element: <TrackOverview />,
        loader: loadArtistTracks,
      },
      {
        path: "artists/:ArtistName/tracks/create",
        element: <CreateTrack />,
        loader: loadReleaseTags,
        action: createTrack
      },
      {
        path: "artists/:ArtistName/tracks/:TrackName",
        element: <Track />,
        loader: loadTrack,
        action: updateReleaseTaskForTrack
      },
      
    ],
  },
]);

const removeStateAndRedirectToCorrectUrl = () => {
  var decoded = decodeURIComponent(window.location.search)
  var path = decoded.substr(decoded.indexOf(";") + 1)
  window.location.href = window.location.origin + path
}

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider
      authority="https://cognito-idp.eu-central-1.amazonaws.com/eu-central-1_CNzkmk0y1"
      client_id="1r1glfk1e8ulq2po5tbf10440o"
      redirect_uri={window.location.origin}
      onSigninCallback={removeStateAndRedirectToCorrectUrl}
    >
      <ThemeProvider theme={darkTheme}>
        <CssBaseline />
        <RouterProvider router={router} />
      </ThemeProvider>
      <ToastContainer />
    </AuthProvider>
  </React.StrictMode>
);